import React from "react";

import css from "./spinner.module.scss";

export function Spinner() {
  return (
    <div className={css.spinner}>
      <div className={css.icon} />
      <span>Ładowanie...</span>
    </div>
  );
}
