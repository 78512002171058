import React from "react";
import Image from "next/image";

import { ActivationMethod } from "resources/AudiotekaApi";
import { Text } from "app-components/text";

import css from "./argumenters-list.module.scss";
import checkMarkGreen from "./check-mark-green.png";
import checkMarkPurple from "./check-mark-purple.png";
import exclamationMark from "./exclamation-mark.png";

type Argumenters = ActivationMethod["purchase_box_argumenters"][number];

function getIconByType(type: Argumenters[number]["type"]) {
  switch (type) {
    case "info":
      return checkMarkGreen;
    case "warning":
      return exclamationMark;
    default:
      return checkMarkPurple;
  }
}

export function ArgumentersList({ list }: { list: Argumenters }) {
  return (
    <ul className={css.argumentersList}>
      {list.map(({ type, field }) => {
        const icon = getIconByType(type);

        return (
          <Text as="li" className={css.item} key={field} type={type === "benefit" ? "bodyNormal" : "subtitleNormal"}>
            <Image loading="lazy" alt="" src={icon.src} height={icon.height} width={icon.width} />
            {field}
          </Text>
        );
      })}
    </ul>
  );
}
