import { CategoryKey } from "./offer-boxes.utils";

type Config = {
  [key in CategoryKey]: {
    badge?: string;
    name: string;
    icons: Array<"visa" | "mastercard" | "blik" | "payu">;
  };
};

export const config: Config = {
  subscription: {
    name: "Miesięcznie",
    icons: ["visa", "mastercard"],
  },
  oneTime180: {
    name: "6 miesięcy",
    icons: ["blik", "payu"],
  },
  oneTime365: {
    name: "12 miesięcy",
    icons: ["blik", "payu"],
  },
};
