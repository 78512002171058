import { ActivationMethod, getUserActivationMethods } from "resources/AudiotekaApi";
import { useUser } from "modules/user";
import { useEffect, useState } from "react";

export function useActivationMethods(visitorMethods: ActivationMethod[]) {
  const user = useUser();

  const [userMethods, setUserMethods] = useState<ActivationMethod[]>(null);

  useEffect(() => {
    setUserMethods(null);

    if (user.isLoggedIn) {
      getUserActivationMethods().then(({ methods }) => {
        setUserMethods(methods);
      });
    }
  }, [user.isLoggedIn, user.id]);

  return {
    activationMethods: userMethods ?? visitorMethods,
    isLoading: user.isLoading || (user.isLoggedIn && !userMethods),
  };
}
