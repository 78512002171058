/* eslint-disable react/no-array-index-key */

import React, { Fragment } from "react";

import { ActivationMethod } from "resources/AudiotekaApi";

import { ArgumentersList } from "./argumenters-list/argumenters-list";
import css from "./argumenters.module.scss";

interface Props {
  argumenters: ActivationMethod["purchase_box_argumenters"];
}

export function Argumenters({ argumenters }: Props) {
  return (
    <div className={css.argumenters}>
      {argumenters.map((section, listIndex) => (
        <Fragment key={`section-${listIndex}`}>
          {listIndex > 0 && <hr className={css.separator} />}
          <ArgumentersList list={section} />
        </Fragment>
      ))}
    </div>
  );
}
