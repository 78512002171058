import React, { ButtonHTMLAttributes } from "react";
import Image from "next/image";
import classNames from "classnames";

import css from "./tab-btn.module.scss";
import blik from "./icons/blik.png";
import mastercard from "./icons/mastercard.png";
import payu from "./icons/payu.png";
import visa from "./icons/visa.png";

const iconSources = { blik, mastercard, payu, visa } as const;

interface Props extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type" | "role"> {
  active?: boolean;
  badge?: string;
  icons: (keyof typeof iconSources)[];
}

export function TabBtn({ active, badge, children, icons, ...props }: Props) {
  return (
    <button className={classNames(css.button, { [css.active]: active })} role="tab" type="button" {...props}>
      {badge && <span className={css.badge}>{badge}</span>}
      {children}
      <span className={css.icons}>
        {icons.map((iconName) => {
          const icon = iconSources[iconName];

          return (
            <Image
              key={iconName}
              loading="lazy"
              alt={iconName}
              className={css.icon}
              src={icon.src}
              height={icon.height}
              width={icon.width}
            />
          );
        })}
      </span>
    </button>
  );
}
