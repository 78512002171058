/* eslint-disable react/no-array-index-key */
import React, { CSSProperties } from "react";

import { CategoryKey } from "../offer-boxes.utils";
import { config } from "../offer-boxes.config";
import { TabBtn } from "./tab-btn/tab-btn";
import css from "./tab-btn-group.module.scss";

interface Props {
  activeCategory: CategoryKey;
  categoryKeys: CategoryKey[];
  disabled?: boolean;
  onClick(categoryKey: CategoryKey): void;
}

export function TabBtnGroup({ activeCategory, categoryKeys, disabled, onClick }: Props) {
  return (
    <div className={css.tabBtnGroup} style={{ "--items-length": categoryKeys.length } as CSSProperties}>
      {categoryKeys.map((categoryKey) => {
        const category = config[categoryKey];

        return (
          <TabBtn
            key={categoryKey}
            active={activeCategory === categoryKey}
            disabled={disabled}
            badge={category.badge}
            icons={category.icons}
            onClick={() => onClick(categoryKey)}
          >
            {category.name}
          </TabBtn>
        );
      })}
    </div>
  );
}
