interface PluralOptions {
  one: string;
  few: string;
  more: string;
}

export function pluralString(count: number, { one, few, more }: PluralOptions) {
  if (count === 1) {
    return one;
  }

  const tens = count % 100;
  const units = tens % 10;

  if ((tens < 10 || tens > 20) && units >= 2 && units <= 4) {
    return few;
  }

  return more;
}
