/* eslint-disable default-case */

import { ActivationMethod } from "resources/AudiotekaApi";

export function categorizeActivationMethods(methods: ActivationMethod[]) {
  const subscription: ActivationMethod[] = [];
  const oneTime180: ActivationMethod[] = [];
  const oneTime365: ActivationMethod[] = [];

  methods.forEach((method) => {
    if (!method.platforms.includes("www")) {
      return;
    }

    switch (method.offer_type) {
      case "subscription":
        subscription.push(method);
        break;
      case "one_time_club_access_180_days":
        oneTime180.push(method);
        break;
      case "one_time_club_access_365_days":
        oneTime365.push(method);
        break;
    }
  });

  return { subscription, oneTime180, oneTime365 };
}

export type CategoryKey = keyof ReturnType<typeof categorizeActivationMethods>;

export function getDurationBasedOnType(offerType: ActivationMethod["offer_type"]) {
  switch (offerType) {
    case "one_time_club_access_180_days":
      return 6;
    case "one_time_club_access_365_days":
      return 12;
    default:
      return 1;
  }
}
