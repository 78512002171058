/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";

import { ActivationMethod } from "resources/AudiotekaApi";

import { OfferBox } from "./offer-box/offer-box";
import { Spinner } from "./spinner/spinner";
import { TabBtnGroup } from "./tab-btn-group/tab-btn-group";
import { categorizeActivationMethods, CategoryKey } from "./offer-boxes.utils";
import { useActivationMethods } from "./use-activation-methods";
import css from "./offer-boxes.module.scss";

interface Props {
  methods?: ActivationMethod[];
}

export function OfferBoxes({ methods = [] }: Props) {
  const { activationMethods, isLoading } = useActivationMethods(methods);

  const categories = categorizeActivationMethods(activationMethods);
  const categoryKeys = Object.keys(categories).filter(
    (categoryKey) => categories[categoryKey].length > 0
  ) as CategoryKey[];

  const [activeCategory, setActiveCategory] = useState<keyof typeof categories>(categoryKeys[0]);

  return (
    <div className={css.offerBoxes}>
      <TabBtnGroup
        activeCategory={activeCategory}
        categoryKeys={categoryKeys}
        disabled={isLoading}
        onClick={(categoryKey) => setActiveCategory(categoryKey)}
      />
      <div className={css.tabPanel} role="tabpanel">
        {isLoading ? (
          <Spinner />
        ) : (
          categories[activeCategory]?.map((method) => <OfferBox key={method.id} method={method} />)
        )}
      </div>
    </div>
  );
}
